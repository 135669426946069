import React from "react"
import { FaExclamation } from 'react-icons/fa'

import Layout from "../components/baseLayout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="container main_content_area error-404 not-found">

      <div className="row full_width_list">
        <div className="col12">
          <div className="error_icon"><FaExclamation /></div>
          <header className="page-header">
            <h1 className="page-title title">Sorry! Page not found.</h1>
          </header>
          <div className="page-content">
            <p>Nothing was found at this location :(</p>
          </div>
        </div>
      </div>

    </section>
  </Layout>
)

export default NotFoundPage



